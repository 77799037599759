import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const auth = useAuth();

  const echo = new Echo({
    broadcaster: "pusher",
    key: runtimeConfig.public.pusherAppKey,
    cluster: runtimeConfig.public.pusherAppCluster,
    forceTLS: true,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          $fetch(
            runtimeConfig.public.apiUrl + "/api/v1/terminal/broadcasting/auth",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              },
              body: JSON.stringify({
                socket_id: socketId,
                channel_name: channel.name,
              }),
            },
          )
            .then((data) => {
              callback(false, data);
            })
            .catch((error) => {
              callback(true, error);
            });
        },
      };
    },
  });

  // Provide Echo instance globally
  nuxtApp.provide("echo", echo);
});
