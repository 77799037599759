import { default as indexlSiup5c1dDMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/index.vue?macro=true";
import { default as login3n47AAhU49Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/login.vue?macro=true";
import { default as _91id_93CPpmILO51AMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/orders/[id].vue?macro=true";
import { default as createALObHCEiuaMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/orders/create.vue?macro=true";
import { default as indexnkoj2hZ9oGMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/orders/index.vue?macro=true";
import { default as print_45jobsc7FTDuoni3Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/print-jobs.vue?macro=true";
import { default as indexsCCJswc5WqMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/reservations/index.vue?macro=true";
import { default as lanesgpMUwNNElwMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/reservations/lanes.vue?macro=true";
import { default as settlementmj0kDXui5BMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/settlement.vue?macro=true";
import { default as setupc6GCv0czl8Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/setup.vue?macro=true";
import { default as editdKtsRje4BdMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexKz2WJFfiSrMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as create2XUPIhiTm6Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/create.vue?macro=true";
import { default as indexEzOl7QaoKPMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statusesR1cMBKtBogMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses.vue?macro=true";
import { default as index7c0xV2seRcMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock/index.vue?macro=true";
import { default as createyMLSpKsuq9Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock/statuses/create.vue?macro=true";
import { default as createjmuIL4WtoxMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock/transfers/create.vue?macro=true";
import { default as indexM5U2NHKJwzMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitx3fJUjbZAaMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93PD7cI38k5aMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createbbmUvjIskfMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93OpuedANF7EMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId].vue?macro=true";
import { default as indexAAaUzgUo13Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/index.vue?macro=true";
import { default as index2EHCKlVYEhMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as splitIfz5jxdpSrMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93niHFj2SNMsMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/[id].vue?macro=true";
import { default as createuo87OWkSCeMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/create.vue?macro=true";
import { default as index4SxblbfvhlMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login3n47AAhU49Meta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: setupc6GCv0czl8Meta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: stock_45statusesR1cMBKtBogMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93PD7cI38k5aMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93niHFj2SNMsMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]