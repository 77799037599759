import validate from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/middleware/01.auth.global.js";
import _02_45locale_45global from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/middleware/02.locale.global.js";
import _03_45employee_45session_45global from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/middleware/03.employeeSession.global.js";
import _04_45has_45closing_45error_45global from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/middleware/04.hasClosingError.global.js";
import manifest_45route_45rule from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241211124657/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45locale_45global,
  _03_45employee_45session_45global,
  _04_45has_45closing_45error_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}